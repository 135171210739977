import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo/logo_claro.svg'
import './style.scss'
const footerLinks = [
    {
        title: 'Enlaces Rápidos', menus: [
            { name: 'Principal', route: '/' },
            { name: 'Experiencia', route: 'practice' },
            { name: 'Nosotros', route: 'attorneys' },
            // { name: 'Casos', route: 'case' },
            // { name: 'Nuestro Blog', route: 'blog' },
        ]
    },
    {
        title: 'Áreas de Experiencia', menus: [
            { name: 'Servicios de Notariado', route: 'practice' },
            { name: 'Cobro Judicial y Administrativo', route: 'practice' },
            { name: 'Derecho Administrativo', route: 'practice' },
            { name: 'Emprendedurismo', route: 'practice' },
            { name: 'Derecho Laboral y Comercial', route: 'practice' },
            { name: 'Asesoría Financiera y Tributaria', route: 'practice' },
            { name: 'Contabilidad y Finanzas', route: 'practice' }
        ]
    },
    {
        title: 'Contáctanos', menus: [
            { name: 'Oficina Central' },
            { name: 'Ciudad Quesada, 200 metros sur y 25 metros este de la iglesia Católica del Barrio San Martín', },
            { name: 'Teléfono y WhatsApp: (+506) 8912-0726', },
            { name: 'Correo electrónico: ia@acyasociados.com', },
        ]
    },
]

const FooterArea = () => {
    return (
        <footer className="footerArea">
            <div className="footerTopArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img src={logo} style={{ width: '100%' }} alt="" />
                                </Link>
                                <p>La solución fácil y práctica a sus problemas legales, contables, administrativos y financieros</p>
                            </div>
                        </div>
                        {footerLinks.map((menu, i) => (
                            <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footerWrap">
                                    <h3>{menu.title}</h3>
                                    <ul>
                                        {menu.menus.map((item, i) => (
                                            <li key={i}>{item.route ? <Link to={`/${item.route}`}>{item.name}</Link> : `${item.name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">
                                <span>2024 AC y Asociados</span>
                            </div>
                            <div className="col-md-4 col-sm-2 col-12">
                                <ul className="socialListFooter">
                                    <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterArea