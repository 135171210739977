import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import HeroSlider from '../../../components/HeroSlider'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Portfolio from '../../../components/Portfolio'
// import Testmonial from "../../../components/Testmonial";
import ContactArea from '../../../components/ContactArea'
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/general/slide1.jpg'
import signature from '../../../images/about/1.png'

// images
import portfolio6 from '../../../images/studies/6.jpeg'
import portfolio7 from '../../../images/studies/7.jpeg'
import portfolio3 from '../../../images/studies/3.jpeg'
import portfolio4 from '../../../images/studies/4.jpeg'
import portfolio9 from '../../../images/studies/9.jpeg'

// general images
import team9 from '../../../images/general/team9.jpg'

const aboutText = {
    text1: 'Acosta y Asociados nació como firma legal y contable, ante la necesidad de diversas empresas y profesionales, de enfrentarse a un mundo cada vez más competitivo, en donde un bufete de abogados, contadores y asesores corporativos, pueda ofrecer soluciones legales para todos sus clientes, en un mismo lugar, es decir, sin importar la naturaleza de la rama del derecho, la contabilidad o gestión financiera en que una persona o compañía requiera asesoría, podrá en Acosta y Asociados, obtener el apoyo que necesita, siempre bajo nuestros pilares fundamentales:',
    items: [
        'Servicios de calidad superior y excelencia.',
        'Precios justos y facilidades de pago.',
        'Comunicación oportuna con nuestros clientes.',
        'Justicia pronta y cumplida.'
    ],
    text2: 'Por tanto, conformamos Acosta y Asociados, con una serie de profesionales con la idoneidad y experiencia necesaria, para atender de forma ágil y eficiente, cualquier tipo de litigio, servicios contables y asesoría o trámite notarial que usted y su empresa necesiten.',
    text3: 'Nuestra relaciones comerciales y servicios, se basan en principios éticos y morales, tales como el trato justo, la ética profesional, la solidaridad, empatía, respeto y sobre todo responsabilidad con cada uno de nuestros clientes.',
}
const heroSliders = [
    {
        images: 'slideWrapperOne', // Image by <a href="https://pixabay.com/users/advogadoaguilar-18212864/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4703922">LEANDRO AGUILAR</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4703922">Pixabay</a>
        title: 'Compromiso, calidad',
        subTitle: 'y eficiencia.',
        text: 'La solución fácil y práctica a sus problemas legales, contables, administrativos y financieros.',
        button: 'Contáctanos ahora.'
    },
    {
        images: 'slideWrapperTwo', // Image by <a href="https://pixabay.com/users/rafabordes-1073020/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1302034">Rafa Bordes</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1302034">Pixabay</a>
        title: 'Todas las respuestas',
        subTitle: 'en una sola firma.',
        text: 'La solución fácil y práctica a sus problemas legales, contables, administrativos y financieros.',
        button: 'Contáctanos ahora'
    },
    {
        images: 'slideWrapperThree', // Image by <a href="https://pixabay.com/users/advogadoaguilar-18212864/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4944555">LEANDRO AGUILAR</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4944555">Pixabay</a>
        title: 'Profesionales en cada',
        subTitle: 'materia del derecho. Contabilidad y Materia Administrativa.',
        text: 'La solución fácil y práctica a sus problemas legales, contables, administrativos y financieros.',
        button: 'Contáctanos ahora'
    },
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Servicios de Notariado',
    },
    {
        icon: 'flaticon-wounded',
        title: 'Cobro Judicial y Administrativo',
    },
    {
        icon: 'flaticon-employee',
        title: 'Derecho Administrativo',
    },
    {
        icon: 'flaticon-house',
        title: 'Contabilidad',
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Emprendedurismo',
    },
    {
        icon: 'flaticon-house',
        title: 'Derecho Laboral y Comercial',
    },
    {
        icon: 'flaticon-house',
        title: 'Asesoría Financiera y Tributaria',
    },
    {
        icon: 'flaticon-house',
        title: 'Asesoría Administrativa',
    },
    {
        icon: 'flaticon-house',
        title: 'Trámites Crediticios',
    },
]

const portfolioItem = [
    { images: portfolio6, title: 'General Service', subtitle: 'Corporate' },
    { images: portfolio7, title: 'Personal Issue', subtitle: 'General' },
    { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
    { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio9, title: 'Business Accounting', subtitle: 'Family Issue' }
]

const HomePageOne = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea" />
            </header>
            <HeroSlider 
                sliders={heroSliders}
                className="heroSliderArea" />
            <Service className="serviceArea" />
            <About
                className="aboutArea"
                title="Sobre Nosotros"
                images={team9}
                signature={signature}
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea"
                title="¿Cómo podemos ayudarte?"
                subTitle="Áreas De Práctica"
                services={services}
            />
            <Portfolio
                className="portfolioArea"
                title="Algunos de Nuestros Clientes"
                subTitle="Aquí Nuestro Mejor Trabajo"
                portfolioItem={portfolioItem}
                slider={true}    
            />
            {/* <Testmonial
                className="testmonialArea"
            /> */}
            <ContactArea
                className="contactArea"
            />
            <TeamMember
                title="Equipo Calificado"
                subTitle="Conoce Nuestros Expertos"
                className="teamArea"
                slider={true}
            />
            <CounterArea
                className="counterArea"
            />
            {/* <BlogArea
                className="blogArea"
                title="Últimas Noticias"
                subTitle="Desde Nuestro Blog"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default HomePageOne