import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from '../Title'
import './style.scss'

// images
import teamMember1 from '../../images/general/team1.jpeg'
import teamMember10 from '../../images/general/team10.jpeg'
// import teamMember3 from '../../images/general/team3.jpg'
// import teamMember4 from '../../images/general/team4.jpeg'
// import teamMember5 from '../../images/general/team5.JPEG'
// import teamMember6 from '../../images/general/team6.jpeg'
// import teamMember7 from '../../images/general/team7.jpeg'
import teamMember8 from '../../images/general/team8.jpeg'
import teamMember11 from '../../images/general/team11.jpeg'
import teamMember12 from '../../images/general/team12.jpeg'

const teams = [
    {
        name: 'Lic. Iván Acosta Castro', level: 'Abogado Litigante - Contador Privado - Licenciado en Contaduría Pública', image: teamMember1, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ]
    },
    {
        name: 'Licda Dayanna López Jiménez', level: 'Abogada Litigante', image: teamMember10, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ]
    },
    {
        name: 'María José Moya Valerio', level: 'Asesora de Empresas', image: teamMember11, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ]
    },
    {
        name: 'CPI Katherine Vargas Campos ', level: 'Contadora Privada y Asesora de Empresas', image: teamMember12, socialMedia: [
            'facebook', 'twitter', 'linkedin'
        ]
    },
    // {
    //     name: 'Licda Amanda Chaves Murillo', level: 'Abogada Litigante', image: teamMember5, socialMedia: [
    //         'facebook', 'twitter', 'linkedin'
    //     ]
    // },
    // {
    //     name: 'Licda Betzy Karina Chiroldes', level: 'Abogada Litigante', image: teamMember6, socialMedia: [
    //         'facebook', 'twitter', 'linkedin'
    //     ]
    // },
    // {
    //     name: 'Licda Natalia Cruz Murillo', level: 'Abogada Litigante', image: teamMember7, socialMedia: [
    //         'facebook', 'twitter', 'linkedin'
    //     ]
    // },
    // {
    //     name: 'Daniel Dambeldor', level: 'Family Lawyer', image: teamMember8, socialMedia: [
    //         'facebook', 'twitter', 'linkedin'
    //     ]
    // }
]

const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const TeamMember = ({ className, title, subTitle, slider, noGutters }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className={!noGutters ? 'row' : 'row no-gutters'}>
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {slider ? (
                        <div className="col-12">
                            <Slider className="teamSlideArea" {...settings}>
                                {teams.map((team, i) => (
                                    <div key={i} className="teamWrapper">
                                        <div className="teamImage">
                                            <img src={team.image} alt="" />
                                        </div>
                                        <div className="teamContent">
                                            <h3>{team.name}</h3>
                                            <span>{team.level}</span>
                                            <ul>
                                                {team.socialMedia.map(social => (
                                                    <li key={social}><a href="#"><i className={`fa fa-${social}`} aria-hidden="true"></i></a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    ) : (
                            <Fragment>
                                {teams.map((team, i) => (
                                    <div key={i} className="col-lg-4 col-md-6 col-12">
                                        <div className="teamWrapper">
                                            <div className="teamImage">
                                                <img src={team.image} alt="" />
                                            </div>
                                            <div className="teamContent">
                                                <h3>{team.name}</h3>
                                                <span>{team.level}</span>
                                                <ul>
                                                    {team.socialMedia.map(social => (
                                                        <li key={social}><a href="#"><i className={`fa fa-${social}`} aria-hidden="true"></i></a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Fragment>
                        )}
                </div>
            </div>
        </div>
    )
}
export default TeamMember