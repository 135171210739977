import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import ServiceArea from '../../../components/ServiceArea'
// import Testmonial from "../../../components/Testmonial";
import PricingTable from "../../../components/PricingTable";
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import breadcumb from '../../../images/general/slide1.jpg'

import './style.scss'

const aboutText = [
    { text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at ,' },
    { text: 'and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum' },
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Servicios de Notariado',
        content: 'Todo en servicios notariales, inscripciones, traspasos, donaciones, hipotecas y prendas, matrimonios, divorcios, constitución de sociedades mercantiles, asociaciones y fundaciones, inscripción de marcas, y cualquier servicio autorizado para realizar en sede notarial.'
    },
    {
        icon: 'flaticon-wounded',
        title: 'Cobro Judicial y Administrativo',
        content: 'Cobro de deudas, procesos monitorios y civiles para la recuperación de sus cuentas por cobrar. Cobro administrativo, servicios especiales para empresas en manejo de su cartera de cuentas por cobrar y recuperación de incobrables.'
    },
    {
        icon: 'flaticon-employee',
        title: 'Derecho Administrativo',
        content: 'Atención de procesos administrativos con instituciones gubernamentales, autónomas y semi autónomas, municipalidades, CCSS, INS, Fodesaf, IMAS, INA, Ministerio de Trabajo, Contencioso Administrativo, y otros. Asesoría en procesos que involucran impuestos, órdenes sanitarias, patentes y contratación administrativa.'
    },
    {
        icon: 'flaticon-house',
        title: 'Contabilidad y Finanzas',
        content: 'Asesoría permanente de profesionales capacitados, para la elaboración y presentación periódica de informes contables para la toma de decisiones en su empresa.'
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Emprendedurismo',
        content: 'Financiamiento, sociedades mercantiles, permisos de funcionamiento, patentes, inscripciones patronales, PYMES, RT, software de facturación y puntos de venta, y demás tramites de inscripción y apertura de nuevas empresas.'
    },
    {
        icon: 'flaticon-house',
        title: 'Derecho Laboral y Comercial',
        content: 'Asesoría laboral, elaboración de contratos, expedientes, procesos sancionatorios y despidos, reglamentos de trabajo, contratos comerciales, confidencialidad, maquila, distribución, servicios profesionales, entre otros.'
    },
    {
        icon: 'flaticon-house',
        title: 'Asesoría Financiera y Tributaria',
        content: 'Apoyo y asesoría en temas financieros tales como créditos hipotecarios y prendarios, leasing, descuento de facturas y otros temas relacionados. Análisis de riesgo, asesoría en el cumplimiento de obligaciones formales y materiales, actualización en temas tributarios, capacitaciones, impuestos, atención de fiscalizaciones, entre otros.'
    },
    {
        icon: 'flaticon-house',
        title: 'Derecho de Familia',
        content: 'Demandas y defensas de procesos de pensión alimentaria, incrementos, rebajos, reconocimiento de paternidad y procesos de filiación, divorcios, liquidación de bienes gananciales, régimen de visitas, procesos de salvaguarda, entre otros.'
    },
    {
        icon: 'flaticon-house',
        title: 'Derecho Civil y Tránsito',
        content: 'Procesos de accidentes de tránsito, interdictos de amparo de posesión, entre otros, procesos ordinarios, responsabilidad objetiva, ejecución de sentencias, remates, cobro de daños y perjuicios, entre otros.'
    },    
]

const breadcumbMenu = [
    {name:'Principal',route:'/'},
    {name:'Experiencia'}
]

const PracticePage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Áreas De Experiencia"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleThree bgFFF"
                title="¿Cómo podemos ayudarte?"
                subTitle="Áreas De Práctica"
                services={services}
            />
            {/* <PricingTable 
                className="pricingTableArea"
                title="Pricing Table"
                subTitle="Our Pricing Plan"
            /> */}
            {/* <Testmonial
                className="testmonialArea pt100"
            /> */}
            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea/>
        </Fragment>
    )
}
export default PracticePage