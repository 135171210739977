import React from 'react'
import './style.scss'
const HeaderTop = props => {
    return (
        <div className={props.className}>
            <div className="container">
                <div className="headerTopMainWrapper">
                    <div className="row">
                        <div className="col-md-7 col-sm-12 col-12 col-lg-8">
                            <ul className="d-flex accountLoginArea">
                                <li><i className="fa fa-map-marker" aria-hidden="true"></i>Ciudad Quesada, 200 metros sur y 25 metros este de la iglesia Católica del Barrio San Martín</li>
                            </ul>
                        </div>
                        <div className="col-md-5 col-sm-12 col-12 col-lg-4">
                            <ul className="headerContact">
                                <li><i className="fa fa-phone"></i> (+506) 8912-0726</li>
                                <li><i className="fa fa-clock-o"></i>L-V 8AM - 5PM</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeaderTop