import React, { Component } from 'react'
import Joi from 'joi-browser'
import { ToastContainer, toast } from 'react-toastify';
// import * as emailjs from 'emailjs-com'
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './style.scss'

const SERVICE_ID = 'service_jk2s10v';
const TEMPLATE_ID = 'template_xgyo5bb';
const USER_ID = 'user_hRP3L2mjfn4W21pirgGeK';

class Form extends Component {

    state = {
        name: '',
        phone: '',
        email: '',
        topic: '',
        description: '',
        error: {}
    }

    notify = () => toast("¡Correo Enviado!");

    schema = {
        email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = 'Correo inválido';
                        break;
                    default:
                        err.message = 'El correo es un campo obligatorio';
                        break;
                }
            });
            return errors;
        }),
        phone: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'El teéfono es un campo obligatorio';
                        break;
                }
            });
            return errors;
        }),
        name: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'El nombre es un campo obligatorio';
                        break;
                }
            });
            return errors;
        }),
        description: Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'La descripción es un campo obligatorio';
                        break;
                }
            });
            return errors;
        }),
        topic: Joi.string(),
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };
    handleChange = (value) => {
        this.setState({
            country: value
        })
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            description: this.state.description,
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };

    submitHandler = event => {
        event.preventDefault()
        const error = this.validate();
        if (error) {
            this.setState({
                error: error || {}
            })
        } else {
            var data = {
                service_id: SERVICE_ID,
                template_id: TEMPLATE_ID,
                user_id: USER_ID,
                template_params: {
                    from: this.state.name,
                    to: 'Acosta y Asociados',
                    message: this.state.description,
                    phone: this.state.phone,
                    topic: this.state.topic === 'Otro' ? 'Tema no seleccionado' : this.state.topic
                }
            };

            axios.post('https://api.emailjs.com/api/v1.0/email/send', data)
              .then(function (response) {
                console.log(response);
              })
              .catch(function (error) {
                console.log(error);
              });




            // let templateParams = {
            //     from: this.state.name,
            //     to: this.state.to,
            //     message: this.state.description,
            //     phone: this.state.phone,
            //     topic: this.state.topic
            // }

            // emailjs.sendForm(
            //     'gmail',
            //     TEMPLATE_ID,
            //     templateParams,
            //     USER_ID
            // )

            // send(
            //     SERVICE_ID,
            //     TEMPLATE_ID,
            //     {
            //         from: this.state.name,
            //         to: this.state.to,
            //         message: this.state.description,
            //         phone: this.state.phone,
            //         topic: this.state.topic
            //     },
            //     USER_ID
            // )
            //     .then((response) => {
            //         console.log('SUCCESS!', response.status, response.text);
            //     })
            //     .catch((err) => {
            //         console.log('FAILED...', err);
            //     });

            this.setState({
                name: '',
                phone: '',
                email: '',
                topic: '',
                description: '',
            })
            console.log(
                'Name' + '=' + this.state.name,
                'Phone' + '=' + this.state.phone,
                'Email' + '=' + this.state.email,
                'topic' + '=' + this.state.topic,
                'Description' + '=' + this.state.description,
            )
            this.notify()
        }
    }

    render() {

        const options = [
            { level: 'Servicios de Notariado', value: 'Notariado' },
            { level: 'Cobro Judicial y Administrativo', value: 'Cobros' },
            { level: 'Derecho Administrativo', value: 'Administrativo' },
            { level: 'Derecho Penal', value: 'Penal' },
            { level: 'Emprendedurismo', value: 'Emprendedurismo' },
            { level: 'Derecho Laboral y Comercial', value: 'Laboral' },
            { level: 'Asesoría Financiera y Tributaria', value: 'Financiero' },
            { level: 'Otro', value: 'Otro' },
        ]

        return (
            <form onSubmit={this.submitHandler} className='contactForm'>
                <div className="row">
                    <div className="col-sm-6 col-12">
                        <div className="formInput">
                            <input
                                placeholder="Nombre"
                                value={this.state.name}
                                name="name"
                                onChange={this.changeHandler}
                                className="form-control"
                                type="text" />
                            {this.state.error.name && <p>{this.state.error.name}</p>}
                        </div>

                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="formInput">
                            <input
                                placeholder="Teléfono"
                                value={this.state.phone}
                                name="phone"
                                onChange={this.changeHandler}
                                className="form-control"
                                type="phone" />
                            {this.state.error.phone && <p>{this.state.error.phone}</p>}
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="formInput">
                            <input
                                placeholder="Correo electrónico"
                                value={this.state.email}
                                name="email"
                                onChange={this.changeHandler}
                                className="form-control"
                                type="email" />
                            {this.state.error.email && <p>{this.state.error.email}</p>}
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="formInput">
                            <select
                                value={this.state.topic}
                                className="form-control"
                                onChange={this.changeHandler}
                                name="topic">
                                {options.map(option => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.level}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="formInput">
                            <textarea
                                className="form-control"
                                value={this.state.description}
                                onChange={this.changeHandler}
                                placeholder="Descripción del caso..."
                                name="description" />
                            {this.state.error.description && <p>{this.state.error.description}</p>}
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit">Enviar</button>
                    </div>
                </div>
            </form>
        )
    }
}
export default Form