import React, { useState, Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import logo from '../../images/logo/logo_claro.svg'
// import logo from '../../images/general/logo4.svg'
import './style.scss'

const HeaderBottom = props => {
    const [search, setSearch] = useState()
    const [responsive, setResponsive] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const submitHandler = e => {
        e.preventDefault()
        console.log(search)
    }
    const clickHandler = () => {
        setTrigger(!trigger)
    }
    const responsiveHandler = () => {
        setResponsive(!responsive)
    }
    return (
        <div className={props.className}>
            <div className="container">
                <div className="headerBottomMainWrapper">
                    <div className="row">
                        <div className="col-lg-2 col-md-10 col-sm-6 col-8">
                            <div className="logo">
                                <NavLink to="/">
                                    <img src={logo} style={{ width: '100%' }} alt="" />
                                </NavLink>
                            </div>
                        </div>
                        <div className={responsive ? "col-lg-8 responsiveWrapper active" : "col-lg-9 responsiveWrapper"}>
                            <ul className="mainMenuWrap">
                                <li><NavLink exact to='/'>Principal</NavLink></li>
                                <li><NavLink exact to='/about'>Nosotros</NavLink></li>
                                {/* <li><NavLink exact to='/practice'>Experiencia</NavLink>
                                    <ul className="subMenu">
                                        <li><NavLink exact to='/practice'>Áreas de Experiencia</NavLink></li>
                                        <li><NavLink exact to='/practice-details'>Áreas de Experiencia Individual</NavLink></li>
                                    </ul>
                                </li> */}
                                <li><NavLink exact to='/practice'>Experiencia</NavLink></li>

                                {/* <li><NavLink exact to='/case-stadies'>Casos</NavLink>
                                    <ul className="subMenu">
                                        <li><NavLink exact to='/case-stadies'>Casos</NavLink></li>
                                        <li><NavLink exact to='/case-stadies-details'>Caso Específico</NavLink></li>
                                    </ul>
                                </li> */}
                                <li><NavLink exact to='/case-stadies'>Clientes</NavLink></li>
                                {/* <li><NavLink exact to='/blog-left'>Noticias</NavLink>
                                    <ul className="subMenu">
                                        <li><NavLink exact to='/blog-left'>Blog Menú Lateral Izquierdo</NavLink></li>
                                        <li><NavLink exact to='/blog-right'>Blog Menú Lateral Derecho</NavLink></li>
                                        <li><NavLink exact to='/blog-fullwidth'>Blog Full Ancho</NavLink></li>
                                    </ul>
                                </li> */}
                                {/* <li><NavLink exact to='/blog-fullwidth'>Noticias</NavLink></li> */}
                                {/* <li><NavLink exact to='/attorneys'>Abogados</NavLink>
                                    <ul className="subMenu">
                                        <li><NavLink exact to='/attorneys'>Abogados</NavLink></li>
                                        <li><NavLink exact to='/attorneys-single'>Abogado Específico</NavLink></li>
                                    </ul>
                                </li> */}
                                <li><NavLink exact to='/attorneys'>Profesionales</NavLink></li>
                                <li><NavLink exact to='/contact'>Contacto</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeaderBottom