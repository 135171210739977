import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import Portfolio from '../../../components/Portfolio'
// images
import breadcumb from '../../../images/general/slide1.jpg'
// images
import portfolio1 from '../../../images/studies/1.jpeg'
import portfolio2 from '../../../images/studies/2.jpeg'
import portfolio3 from '../../../images/studies/3.jpeg'
import portfolio4 from '../../../images/studies/4.jpeg'
import portfolio5 from '../../../images/studies/5.jpeg'
import portfolio6 from '../../../images/studies/6.jpeg'
import portfolio7 from '../../../images/studies/7.jpeg'
import portfolio8 from '../../../images/studies/8.jpeg'
import portfolio9 from '../../../images/studies/9.jpeg'
import portfolio10 from '../../../images/studies/10.jpeg'
// import portfolio11 from '../../../images/studies/11.jpeg'
import portfolio12 from '../../../images/studies/12.jpeg'
import portfolio13 from '../../../images/studies/13.jpeg'
import portfolio14 from '../../../images/studies/14.jpeg'
import portfolio15 from '../../../images/studies/15.jpeg'
import portfolio16 from '../../../images/studies/16.jpeg'
import portfolio17 from '../../../images/studies/17.jpeg'
import portfolio18 from '../../../images/studies/18.jpeg'
import portfolio19 from '../../../images/studies/19.jpeg'
import portfolio20 from '../../../images/studies/20.jpeg'
import portfolio21 from '../../../images/studies/21.jpeg'
import portfolio22 from '../../../images/studies/22.jpeg'
import portfolio23 from '../../../images/studies/23.jpeg'
import portfolio24 from '../../../images/studies/24.jpeg'
import portfolio25 from '../../../images/studies/25.jpeg'
import portfolio26 from '../../../images/studies/26.jpeg'
import portfolio27 from '../../../images/studies/27.jpeg'
import portfolio28 from '../../../images/studies/28.jpeg'
import portfolio29 from '../../../images/studies/29.jpeg'
import portfolio30 from '../../../images/studies/30.jpeg'
import portfolio31 from '../../../images/studies/31.jpeg'
import portfolio32 from '../../../images/studies/32.jpeg'
import portfolio33 from '../../../images/studies/33.jpeg'
import portfolio34 from '../../../images/studies/34.jpeg'
import portfolio35 from '../../../images/studies/35.jpeg'
import portfolio36 from '../../../images/studies/36.jpeg'
import portfolio37 from '../../../images/studies/37.jpeg'
import portfolio38 from '../../../images/studies/38.jpeg'
import portfolio39 from '../../../images/studies/39.jpeg'
import portfolio40 from '../../../images/studies/40.jpeg'
import portfolio41 from '../../../images/studies/41.jpeg'
import portfolio42 from '../../../images/studies/42.jpeg'
import portfolio43 from '../../../images/studies/43.jpeg'
import portfolio44 from '../../../images/studies/44.jpeg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Principal', route: '/' },
    { name: 'Clientes' }
]

const portfolioItem = [
    { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
    { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
    { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
    { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' },
    { images: portfolio6, title: 'General Service', subtitle: 'Corporate' },
    { images: portfolio7, title: 'Personal Issue', subtitle: 'General' },
    { images: portfolio8, title: 'Business Accounting', subtitle: 'Business' },
    { images: portfolio9, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio10, title: 'Accounting issue', subtitle: 'Criminal' },
    // { images: portfolio11, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio12, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio13, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio14, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio15, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio16, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio17, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio18, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio19, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio20, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio21, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio22, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio23, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio24, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio25, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio26, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio27, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio28, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio29, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio30, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio31, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio32, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio33, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio34, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio35, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio36, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio37, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio38, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio39, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio40, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio41, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio42, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio43, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio44, title: 'Accounting issue', subtitle: 'Criminal' },
]

const PortfolioPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton
                    className="headerBottomArea headerBottomAreaStyelTwo"
                />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Clientes"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />

            <Portfolio
                title="Nuestra Cartera de Clientes"
                subTitle="Aquí Nuestro Mejor Trabajo"
                fullWidth={true}
                portfolioItem={portfolioItem}
                className="portfolioArea portfolioAreaStyleTwo"
            />

            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default PortfolioPage