import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
// import Testmonial from "../../../components/Testmonial";
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/general/team9.jpg'
import breadcumb from '../../../images/general/slide1.jpg'

import './style.scss'

const aboutText = {
    text1: 'Acosta y Asociados nació como firma legal y contable, ante la necesidad de diversas empresas y profesionales, de enfrentarse a un mundo cada vez más competitivo, en donde un bufete de abogados, contadores y asesores corporativos, pueda ofrecer soluciones legales para todos sus clientes, en un mismo lugar, es decir, sin importar la naturaleza de la rama del derecho, la contabilidad o gestión financiera en que una persona o compañía requiera asesoría, podrá en Acosta y Asociados, obtener el apoyo que necesita, siempre bajo nuestros pilares fundamentales:',
    items: [
        'Servicios de calidad superior y excelencia.',
        'Precios justos y facilidades de pago.',
        'Comunicación oportuna con nuestros clientes.',
        'Justicia pronta y cumplida.'
    ],
    text2: 'Por tanto, conformamos Acosta y Asociados, con una serie de profesionales con la idoneidad y experiencia necesaria, para atender de forma ágil y eficiente, cualquier tipo de litigio, servicios contables y asesoría o trámite notarial que usted y su empresa necesiten.',
    text3: 'Nuestra relaciones comerciales y servicios, se basan en principios éticos y morales, tales como el trato justo, la ética profesional, la solidaridad, empatía, respeto y sobre todo responsabilidad con cada uno de nuestros clientes.',
}

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Servicios de Notariado',
    },
    {
        icon: 'flaticon-wounded',
        title: 'Cobro Judicial y Administrativo',
    },
    {
        icon: 'flaticon-employee',
        title: 'Derecho Administrativo',
    },
    {
        icon: 'flaticon-thief',
        title: 'Derecho Penal',
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Emprendedurismo',
    },
    {
        icon: 'flaticon-house',
        title: 'Derecho Laboral y Comercial',
    },
    {
        icon: 'flaticon-house',
        title: 'Asesoría Financiera y Tributaria',
    },
]

const breadcumbMenu = [
    { name: 'Principal', route: '/' },
    { name: 'Nosotros' }
]

const AboutPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Nosotros"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="Nosotros"
                subTitle="Somos Expertos"
                images={about}
                orderLast="order-last"
                // videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="¿Cómo podemos ayudarte?"
                subTitle="Áreas De Práctica"
                services={services}
            />
            {/* <Testmonial
                className="testmonialArea pt100"
            /> */}
            <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="Profesionales Calificados"
                subTitle="Conoce Nuestros Expertos"
                className="teamArea"
                slider={true}
            />
            {/* <BlogArea
                className="blogArea"
                title="Últimas Noticias"
                subTitle="Nuestro Blog"
            /> */}
            {/* <NewsLetter
                className="newsLetterArea"
            /> */}
            <FooterArea />
        </Fragment>
    )
}
export default AboutPage