import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './style.scss'

const services = [
    { icon: 'fi flaticon-lawyer', subtitle: 'Reserve Su', title: 'Cita' },
    { icon: 'fi flaticon-scale', subtitle: 'Obtenga Asesoramiento', title: 'Especializado' },
    { icon: 'fi flaticon-network', subtitle: 'Puedes Unirte A Nuestro', title: 'Equipo' },
]
const OFFICE_NUMBER = '+50689120726';

const Service = props => {

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div className={props.className}>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Únete a nuestro equipo</ModalHeader>
                <ModalBody>
                    Envíanos tu hoja de vida, en conjunto con tu título universitario y de incorporación al Colegio de Abogados al correo <strong>ia@acyasociados.com</strong>
                </ModalBody>
                <ModalFooter>
                    <Button className="btnStyle" onClick={toggle}>Entendido</Button>{' '}
                </ModalFooter>
            </Modal>
            <div className="container">
                <div className="row">
                    {services.map((service, i) => {
                        if (i === 2) {
                            return <div onClick={toggle} style={{ cursor: 'pointer' }} key={i} className="col-lg-4 col-md-6">
                                <div className="serviceItem">
                                    <div className="serviceIcon">
                                        <i className={service.icon}></i>
                                    </div>
                                    <div className="serviceText">
                                        <span>{service.subtitle}</span>
                                        <h3>{service.title}</h3>
                                    </div>
                                </div>
                            </div>
                        } else {
                            return <div key={i} className="col-lg-4 col-md-6">
                                <a href={`https://wa.me/${OFFICE_NUMBER}`} target="_blank" style={{ color: 'inherit' }}>
                                    <div className="serviceItem">
                                        <div className="serviceIcon">
                                            <i className={service.icon}></i>
                                        </div>
                                        <div className="serviceText">
                                            <span>{service.subtitle}</span>
                                            <h3>{service.title}</h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        }
                    })}
                </div>
            </div>
        </div>
    )
}
export default Service